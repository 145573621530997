'use client';

import { ReactNode } from 'react';
// import { useScreenStore } from '@eppay/stores/screen';
import { Box, ClientGate, EpisodeThemeProvider } from '@episode-ui';

const PageClientLayout = ({ children }: { children: ReactNode }) => {
  // const { isDisableScreen, setDisableScreen } = useScreenStore();

  // useEffect(() => {
  //   // setDisableScreen(true);
  //   return () => {
  //     setDisableScreen(false);
  //   };
  // }, []);

  return (
    <>
      <ClientGate>
        <EpisodeThemeProvider>
          <Box sx={{ position: 'relative', backgroundColor: 'white', padding: 0, margin: 0 }}>{children}</Box>
        </EpisodeThemeProvider>
      </ClientGate>
      {/* {isDisableScreen && (
        <Box
          position={'absolute'}
          display={'flex'}
          top={0}
          width={'100%'}
          height={'100%'}
          bgcolor={'transparent'}
          zIndex={1500}
        >
          <Box
            sx={{
              position: 'fixed',
              display: 'flex',
              top: 0,
              right: '50%',
              width: '1px',
              height: '1px',
              background: Colors.ep_black,
            }}
          />
        </Box>
      )} */}
    </>
  );
};

export default PageClientLayout;
